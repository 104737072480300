<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>入库商品分类管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()"
                >添加入库商品分类</el-button
              >
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="commodityCateData"
            show-checkbox
            :expand-on-click-node="false"
            row-key="id"
            :tree-props="{ children: 'son', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="name" label="入库商品分类名称">
              <template slot-scope="scope">
                <span>
                  <i :class="scope.row.icon"></i> {{ scope.row.label }}
                  {{ scope.row.name }} {{ scope.row.level }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="number" label="入库商品分类编号">
            </el-table-column>
            <el-table-column prop="state" label="入库商品状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @click.native="changeOpen(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column width="260" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-plus"
                  @click="pAdd(scope.row)"
                  >增加</el-button
                >

                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="cateDialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="cateFormRules" ref="cateFormRef" :model="cateForm">
        <el-form-item
          prop="name"
          label="分类名称"
          :label-width="formLabelWidth"
        >
          <el-input @change="handleChange" v-model="cateForm.name"></el-input>
        </el-form-item>
        <el-form-item
          prop="order"
          label="商品分类排序"
          :label-width="formLabelWidth"
        >
          <el-input @change="handleChange" v-model="cateForm.order"></el-input>
        </el-form-item>

        <el-form-item
          prop="task_id"
          label="关联任务"
          :label-width="formLabelWidth"
        >
          <el-cascader
            @change="handleChange"
            placeholder="选择关联任务"
            :options="optionsTask"
            :props="optionPropsTask"
            v-model="cateForm.task_id"
            filterable
          ></el-cascader>
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 更改状态提示框 -->
    <el-dialog
      :visible.sync="changeDialogVisible"
      :before-close="handleChangeClose"
      width="30%"
      title="提示"
    >
      <div class="content">
        <div class="warning-icon" />
        <span class="text">{{ warningText }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          @click="
            changeDialogVisible = false
            getCommodityCateList()
          "
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="confirmChangeState"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
import {
  getCommodityCateList,
  addCommodityCate,
  editCommodityCate,
  delCommodityCate,
  getCommodityCateInfo,
  changeCommodityCateState,
} from '@/api/psi/commoditycate.js'
export default {
  data() {
    return {
      isEdit: false,
      dialogLog: {
        state: false,
      },
      tableLoading: false,
      warningText: '',
      commodityCateData: [],
      cateDialogVisible: false,
      changeDialogVisible: false,
      cateFormRules: {
        name: {
          required: true,
          message: '请输入入库商品分类名称',
          trigger: 'blur',
        },
        order: { required: true, message: '请输入商品分类列表排序' },
      },
      title: '添加入库商品分类',
      addOrEdit: 1,
      formLabelWidth: '120px',
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      cateForm: {
        name: '',
        order: '',
        pid: 0,
        task_id: '',
      },
    }
  },
  created() {
    this.getCommodityCateList()
  },
  methods: {
    async getCommodityCateList() {
      this.tableLoading = true
      const { data: res } = await getCommodityCateList()
      console.log(res)
      if (res.code === 0) {
        this.commodityCateData = res.data
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getCommodityCateList()
          done()
        })
        .catch((_) => {})
    },
    async confirmChangeState() {
      const res = await changeCommodityCateState({
        goods_class_id: this.row.id,
        task_id: '',
      })
      console.log(res)
      this.$message.success('修改状态成功')

      // this.$message.success(res.message)

      this.changeDialogVisible = false
      this.getCommodityCateList()
    },
    // 取消
    cancelAE() {
      this.cateDialogVisible = false
      this.title = '添加入库商品分类'
      this.isEdit = false

      this.addOrEdit = 1
      this.cateForm = {
        name: '',
        order: '',
        pid: 0,
        task_id: '',
      }
      this.$refs.cateFormRef.clearValidate()
    },
    // 状态修改
    async changeOpen(row) {
      console.log(row)
      this.row = row
      this.changeDialogVisible = true
      if (row.status === 0) {
        // 即将禁用
        this.warningText =
          '此操作将隐藏该入库商品分类, 禁用所有功能不可用，并计入操作日志，是否继续?'
      } else if (row.status === 1) {
        // 即将开启
        this.warningText =
          '此操作将显示 该入库商品分类,启用后开启所有功能，并计入操作日志，是否继续?'
      }
    },
    // 新增打开
    pAdd(row) {
      console.log(row)
      if (row) {
        console.log(row.id)
        this.cateForm.pid = row.id
      } else {
        this.cateForm.pid = 0
      }
      this.cateDialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      this.cateDialogVisible = true
      this.title = '编辑入库商品分类'
      this.addOrEdit = 2
      const { data: res } = await getCommodityCateInfo({
        goods_class_id: row.id,
      })

      this.cateForm = res.data
      this.cateForm.goods_class_id = row.id
    },
    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delCommodityCate({
            goods_class_id: row.id,
            task_id: '',
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getCommodityCateList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE() {
      this.$refs.cateFormRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            const { data: res } = await addCommodityCate(this.cateForm)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getCommodityCateList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
          } else {
            // 编辑

            if (this.isEdit === true) {
              const { data: res } = await editCommodityCate(this.cateForm)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getCommodityCateList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../../../assets/exclamationmark.png') no-repeat;
}
</style>
