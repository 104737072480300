import { post } from '@/utils/request'

//获取商品分类列表
export function getCommodityCateList(data) {
    return post('/admin/goods/goodsClassList', data)
}

// 添加入库商品分类
export function addCommodityCate(data) {
    return post('/admin/Goods/addGoodsClass', data)
}

// 修改入库商品分类
export function editCommodityCate(data) {
    return post('/admin/goods/editGoodsClass', data)
}
// 删除入库商品分类

export function delCommodityCate(data) {
    return post('/admin/goods/delGoodsClass', data)
}

// 编辑回显信息接口
export function getCommodityCateInfo(data) {
    return post('/admin/goods/goodsClassInfo', data)
}

// 修改状态接口
export function changeCommodityCateState(data) {
    return post('/admin/goods/editStatus', data)
}
